import {Consts} from "./Consts";
import dayjs from 'dayjs';

export interface AppInfo {
    appId: string
    iOS?: AppVersion
    android?: AppVersion
    privacyPolicy: InternalPolicyInfo
    termOfUse: InternalPolicyInfo
    localized: {[key: string]: InternalLocalizedAppInfo}
}

export interface AppVersion {
    latestVersion: string
    minOSVersion: string
}

interface InternalPolicyInfo {
    dateString: string
    templateNo: string
}

interface InternalLocalizedAppInfo {
    appName: string
}

export interface LocalizedAppInfo {
    appId: string
    appName: string
    contactEmail: string
    contactEmailUrl: string
    contactEmailUrlForIOS?: string
    contactEmailUrlForAndroid?: string
    termsLangCode: string
    privacyPolicy: LocalizedPolicyInfo
    termOfUse: LocalizedPolicyInfo
}

export interface LocalizedPolicyInfo {
    dateString: string
    localizedDateString: string
    templateNo: string
}

export const AppInfoArray: Array<AppInfo> = [
    {
        appId: "bpressure",
        iOS: {
            latestVersion: "3.4",
            minOSVersion: "13.0"
        },
        android: {
            latestVersion: "3.5",
            minOSVersion: "21.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01"
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01"
        },
        localized: {
            ja: {
                appName: "シンプル血圧手帳",
            },
            en: {
                appName: "Simple Blood Pressure Recorder",
            },
            zh_hans: {
                appName: "简易血压记录",
            },
            zh_hant: {
                appName: "簡單血壓記錄",
            },
            ko: {
                appName: "심플 혈압 기록",
            }
        }
    },
    {
        appId: "bsugar",
        iOS: {
            latestVersion: "2.6",
            minOSVersion: "13.0"
        },
        android: {
            latestVersion: "3.2",
            minOSVersion: "21.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        localized: {
            ja: {
                appName: "シンプル血糖値ノート",
            },
            en: {
                appName: "Simple Blood Glucose Note",
            },
            zh_hans: {
                appName: "简便的血糖值记录本",
            },
            zh_hant: {
                appName: "簡易血糖值筆記本",
            },
            ko: {
                appName: "간단한 혈당치 노트"
            }
        }
    },
    {
        appId: "fukuri",
        iOS: {
            latestVersion: "22.3",
            minOSVersion: "13.0"
        },
        android: {
            latestVersion: "2.0",
            minOSVersion: "21.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        localized: {
            ja: {
                appName: "シンプル福利計算",
            },
            en: {
                appName: "Simple Compound Interest",
            },
            zh_hans: {
                appName: "简易复利计算",
            },
            zh_hant: {
                appName: "簡單複利計算",
            },
            ko: {
                appName: "심플 복리 계산"
            }
        }
    },
    {
        appId: "revo",
        iOS: {
            latestVersion: "2.1",
            minOSVersion: "13.0"
        },
        android: {
            latestVersion: "2.2",
            minOSVersion: "21.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        localized: {
            ja: {
                appName: "リボ払い計算",
            }
        }
    },
    {
        appId: "alc",
        iOS: {
            latestVersion: "2.7",
            minOSVersion: "13.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        localized: {
            ja: {
                appName: "休肝日記録",
            },
            en: {
                appName: "AbstinenceLogger",
            },
            zh_hans: {
                appName: "禁酒记录",
            },
            zh_hant: {
                appName: "禁酒記錄",
            },
            ko: {
                appName: "금주 기록"
            }
        }
    },
    {
        appId: "kaiben",
        iOS: {
            latestVersion: "1.2",
            minOSVersion: "13.0"
        },
        privacyPolicy: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        termOfUse: {
            dateString: "2021-01-01",
            templateNo: "01",
        },
        localized: {
            ja: {
                appName: "快便記録"
            },
            en: {
                appName: "Smooth Movements Logger",
            },
            zh_hans: {
                appName: "通畅记录",
            },
            zh_hant: {
                appName: "順暢記錄",
            },
            ko: {
                appName: "배변 기록"
            }
        }
    },
]

export function getAppInfoByAppId(appId: string): AppInfo|null {
    return AppInfoArray.find((appInfo) => {
        return appInfo.appId === appId
    }) || null
}

export function getLocalizedAppInfoArray(lang: string): Array<LocalizedAppInfo> {
    let localizedArray: Array<LocalizedAppInfo> = []
    for (const appInfo of AppInfoArray) {
        if (!appInfo.localized[lang]) {
            continue
        }

        const appName = appInfo.localized[lang].appName
        let termsLangCode: string = lang
        if (lang.startsWith("zh")) {
            // zh_hans,zh_hantはフランス語みたいになるので明示的に指定
            dayjs.locale("zh-cn")
            termsLangCode = "zh"
        } else {
            dayjs.locale(lang)
        }

        let localizedAppInfo: LocalizedAppInfo = {
            appId: appInfo.appId,
            appName: appName,
            contactEmail: Consts.contactEmail,
            contactEmailUrl: emailUrlByAppName(appName),
            termsLangCode: termsLangCode,
            privacyPolicy: {
                dateString: appInfo.privacyPolicy.dateString,
                localizedDateString: dayjs(appInfo.privacyPolicy.dateString).format('LL'),
                templateNo: appInfo.privacyPolicy.templateNo
            },
            termOfUse: {
                dateString: appInfo.termOfUse.dateString,
                localizedDateString: dayjs(appInfo.termOfUse.dateString).format('LL'),
                templateNo: appInfo.termOfUse.templateNo
            }
        }
        if (appInfo.iOS) {
            localizedAppInfo.contactEmailUrlForIOS = emailUrlByAppName(appName, " for iOS")
        }
        if (appInfo.android) {
            localizedAppInfo.contactEmailUrlForAndroid = emailUrlByAppName(appName, " for Android")
        }
        localizedArray.push(localizedAppInfo)
    }
    return localizedArray
}

export function getLocalizedAppInfo(lang: string, appId: string): LocalizedAppInfo {
    const array = getLocalizedAppInfoArray(lang)
    let appInfo = array.find((appInfo) => {
        return appInfo.appId === appId
    })!
    if (appInfo) { return appInfo }
    appInfo = array.find((appInfo) => {
        return appInfo.appId === "en"
    })!
    return appInfo
}

function emailUrlByAppName(appName: string, appendSubject: string = ""): string {
    const escapedAppName = encodeURIComponent(appName + " " + appendSubject)
    const email = Consts.contactEmail
    return `mailto:${email}?subject=${escapedAppName}`
}
